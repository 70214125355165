
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';


@Component({
  computed: {
    ...mapState(['manage']),
    tableHeaders() {
      return [
        { text: 'Name', value: 'name' },
        { text: 'ID', value: '_id' }
      ];
    }
  },
  data: () => ({
    loading: true
  })
})
export default class Manage extends Vue {
  manage: any;

  search = '';

  loading = false;

  get model() {
    return this.$attrs.type;
  }

  load() {
    this.loading = true;
    Promise.all([
      this.$store.dispatch('RETRIEVE_MANAGE_SCHEMA', { model: this.model }),
      this.$store.dispatch('RETRIEVE_MANAGE_SCHEMA_RELATIONSHIPS', { model: this.model }),
      this.$store.dispatch('RETRIEVE_MANAGE_LIST', { model: this.model }),
    ]).then(() => {
      // if (!this.manage.list.length) {
      //   this.loading = false;
      // }
    });
  }

  count() {
    return this.manage.list.length || 0;
  }

  editItem() {
    // this.loading = false;
    // Go to edit view /data/manage/model/id
  }

  deleteItem(_id: string) {
    // Call delete action and reload page
    /* eslint-disable */
    if (window.confirm(`Are you sure you want to delete ${_id}? This is irreversable.`)) {
        this.$store.dispatch('DELETE_MANAGE_ITEM', { model: this.model, _id }).then(() => {
          this.load();
        });
    }
  }

  duplicateItem(item: any) {
    // Call duplicate action and reload page
    /* eslint-disable */
    if (window.confirm(`Are you sure you want to duplicate and create "${item.name}"? This does not duplicate child items, just a shallow clone.`)) {
      this.$store.dispatch('DUPLICATE_MANAGE_ITEM', { model: this.model, item }).then(() => {
        this.load();
      });
    }
  }


  mounted() {
    this.$store.dispatch('CLEAR_MANAGE_STATE').then(() => {
      this.load();
    });
  }

  @Watch('manage.list')
  onListChange(list, oldList) {
    if (list.length || (list !== oldList)) {
      this.loading = false;
    }
  }
}
